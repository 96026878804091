export const DealersData = [
  { name: "Zamaka Properties", image: "zamaka.png", properties: 12 },
  {
    name: "Al-Farooq Real Estate & Marketing",
    image: "alfarooq.png",
    properties: 5,
  },
  { name: "JS Assossiates", image: "JS.png", properties: 15 },
  {
    name: "Khyber Pass Marketing & Property Dealers",
    image: "khyber-pass2.png",
    properties: 10,
  },
  { name: "IMCC Builders & Developers", image: "lmcc.png", properties: 30 },
  { name: "Mir Brothers", image: "mir-brothers.png", properties: 5 },
  { name: "Prime Way", image: "Prime-Way.png", properties: 25 },
  { name: "SSB Marketing", image: "SSB-Marketing-1.png", properties: 14 },
  {
    name: "Jaji Group of Companies",
    image: "jaji-groups-1.png",
    properties: 20,
  },
  { name: "JS Assossiates", image: "JS.png", properties: 15 },
  {
    name: "Khyber Pass Marketing & Property Dealers",
    image: "khyber-pass2.png",
    properties: 10,
  },
  { name: "IMCC Builders & Developers", image: "lmcc.png", properties: 30 },
  { name: "Mir Brothers", image: "mir-brothers.png", properties: 5 },
  { name: "Prime Way", image: "Prime-Way.png", properties: 25 },
  { name: "SSB Marketing", image: "SSB-Marketing-1.png", properties: 14 },
  { name: "JS Assossiates", image: "JS.png", properties: 15 },
  {
    name: "Khyber Pass Marketing & Property Dealers",
    image: "khyber-pass2.png",
    properties: 10,
  },
  { name: "IMCC Builders & Developers", image: "lmcc.png", properties: 30 },
  { name: "Mir Brothers", image: "mir-brothers.png", properties: 5 },
  { name: "Prime Way", image: "Prime-Way.png", properties: 25 },
  { name: "SSB Marketing", image: "SSB-Marketing-1.png", properties: 14 },
  { name: "JS Assossiates", image: "JS.png", properties: 15 },
  {
    name: "Khyber Pass Marketing & Property Dealers",
    image: "khyber-pass2.png",
    properties: 10,
  },
  { name: "IMCC Builders & Developers", image: "lmcc.png", properties: 30 },
  { name: "Mir Brothers", image: "mir-brothers.png", properties: 5 },
  { name: "Prime Way", image: "Prime-Way.png", properties: 25 },
  { name: "SSB Marketing", image: "SSB-Marketing-1.png", properties: 14 },
];
