export const PropertiesData = [
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "123 Main Street",
    rent: 1500,
    dealer: "ABC Realty",
    amenities: ["Swimming Pool", "Gym", "Parking"],
    type: "Apartment",
    Rooms: 2,
    Bathrooms: 1,
    Size: 10,
    profile:
      "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "456 Oak Avenue",
    rent: 2000,
    dealer: "XYZ Properties",
    amenities: ["Balcony", "Laundry", "Pet-friendly"],
    type: "House",
    Rooms: 3,
    Bathrooms: 2,
    Size: 15,
    profile:
      "https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "789 Pine Lane",
    rent: 1800,
    dealer: "DEF Real Estate",
    amenities: ["Central Heating", "Security System"],
    type: "Condo",
    Rooms: 1,
    Bathrooms: 1,
    Size: 8,
    profile:
      "https://images.pexels.com/photos/3935333/pexels-photo-3935333.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "567 Elm Street",
    rent: 1700,
    dealer: "GHI Realty",
    amenities: ["Fitness Center", "Garage"],
    type: "Apartment",
    Rooms: 2,
    Bathrooms: 1.5,
    Size: 12,
    profile:
      "https://images.pexels.com/photos/259588/pexels-photo-259588.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "890 Maple Avenue",
    rent: 2200,
    dealer: "JKL Properties",
    amenities: ["Fireplace", "Patio", "Security System"],
    type: "House",
    Rooms: 4,
    Bathrooms: 3,
    Size: 20,
    profile:
      "https://images.pexels.com/photos/280222/pexels-photo-280222.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "432 Birch Lane",
    rent: 1600,
    dealer: "MNO Real Estate",
    amenities: ["Balcony", "Laundry"],
    type: "Condo",
    Rooms: 2,
    Bathrooms: 1,
    Size: 9,
    profile:
      "https://images.pexels.com/photos/1396132/pexels-photo-1396132.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "987 Cedar Street",
    rent: 1900,
    dealer: "PQR Realty",
    amenities: ["Swimming Pool", "Gym", "Parking"],
    type: "Apartment",
    Rooms: 3,
    Bathrooms: 2,
    Size: 15,
    profile:
      "https://images.pexels.com/photos/210617/pexels-photo-210617.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "654 Walnut Avenue",
    rent: 2100,
    dealer: "STU Properties",
    amenities: ["Central Heating", "Garage"],
    type: "House",
    Rooms: 3,
    Bathrooms: 2.5,
    Size: 18,
    profile:
      "https://images.pexels.com/photos/280229/pexels-photo-280229.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "321 Sycamore Lane",
    rent: 1750,
    dealer: "VWX Real Estate",
    amenities: ["Fitness Center", "Patio"],
    type: "Condo",
    Rooms: 2,
    Bathrooms: 1.5,
    Size: 11,
    profile:
      "https://images.pexels.com/photos/277667/pexels-photo-277667.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    purpose: "rent",
    title: "Property for Rent in DHA Phase 6",
    description: "This property is an amazing place to rent in DHA.",
    address: "876 Oak Street",
    rent: 2000,
    dealer: "YZA Realty",
    amenities: ["Fireplace", "Laundry", "Pet-friendly"],
    type: "House",
    Rooms: 4,
    Bathrooms: 3,
    Size: 22,
    profile:
      "https://images.pexels.com/photos/3555615/pexels-photo-3555615.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];
