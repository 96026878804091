import { Link } from "react-router-dom";
import { DealersData } from "../../data/dealers";
import { MARKHOR_STORAGE } from "../../config/mstorage";

export default function DealersSm() {
  return (
    <div className="py-14">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="text-gray-500 text-xl font-semibold">
            Featured Agents
          </h3>
          <p className="text-gray-600 mt-3">
            Find the best Agents to get your Property.
          </p>
        </div>
        <div className="mt-12 justify-center">
          <div className="masonry-grid masonry-grid-count-4">
            {Object.values(DealersData)
              .slice(0, 8)
              .map((d, index) => (
                <div className="masonry-grid__item !p-4 text-center border">
                  <Link to={"../dealers"}>
                    <div className="inline-block">
                      <img
                        src={MARKHOR_STORAGE + d.image}
                        alt={d.name}
                        className="w-full"
                      />
                    </div>
                    <h1 className="truncate">{d.name}</h1>
                    <p className="text-center mt-2 text-xs">
                      Properties: {d.properties}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div className="text-center mt-10 px-3">
          <Link to="../dealers">
            <button
              type="button"
              className="bg-green-100 border border-green-600 rounded-full text-green-800 px-3 py-2 hover:bg-green-300 active:bg-green-400"
            >
              See All the Dealers
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
