import { MARKHOR_STORAGE } from "../config/mstorage";

export const ProjectsData = [
  {
    id: 1,
    name: "CITY CENTER MALL & APARTMENT",
    location: "Main University Road, Arbab Road Peshawar.",
    imageLink: MARKHOR_STORAGE + "city-center-768x521.jpg",
    category: "Commercial & Residential High-rise",
    description:
      "Indulge in upscale living at City Center Mall & Apartments, centrally located in the heart of Peshawar and just a 1-minute walk from the BRT Station. With bookings starting at just 15% and flexible 36-month installments, choose from 1, 2, and 3-bedroom apartments. With 40% of construction already completed, relish in modern amenities, breathtaking panoramic views, and the perfect harmony of comfort and convenience at City Center.",
  },
  {
    id: 2,
    name: "JAJI HEIGHTS PESHAWAR",
    location: "Min University Town, Old Bara Road Peshawar",
    imageLink: MARKHOR_STORAGE + "Jaji-2-768x543.jpg",
    category: "Commercial & Residential High-rise",
    description:
      "The pioneering project by Markhor Marketing & Developers, ideally situated in Peshawar University Town. Seize the opportunity with a 15% booking and 45-month installment plan. Benefit from a 10% discount on full payment. With swift sales and satisfied clients, we've achieved our target rapidly. Re-selling bookings are now open, inviting you to be part of this success story.",
  },
  {
    id: 3,
    name: "JANNAT RESIDENCY",
    location: "Main Hayatabad Ring Road, Next to HBK Hypermarket Peshawar",
    imageLink: MARKHOR_STORAGE + "jannatResidency.jpg",
    category: "Commercial & Residential High-rise",

    description:
      "Elevate your lifestyle at Jannat Residency & Jannat Gold Mall Highrise Project, strategically located on the Main Ring Road beside HBK Hypermarket Peshawar. Offering diverse spaces, including Commercial Shops, Gold & Gems Shops, Corporate Offices, and 1 to 2-bedroom Apartments, with a convenient 20% booking and 40-month installments. Act quickly, as 80% of the inventory is already sold out, and enjoy zero minutes away from HBK Arena, Cheezious, MacDonald's, and KFC.",
  },
  {
    id: 2,
    name: "JAJI HEIGHTS PESHAWAR",
    location: "Min University Town, Old Bara Road Peshawar",
    imageLink: MARKHOR_STORAGE + "Jaji-2-768x543.jpg",
    category: "Commercial & Residential High-rise",
    description:
      "The pioneering project by Markhor Marketing & Developers, ideally situated in Peshawar University Town. Seize the opportunity with a 15% booking and 45-month installment plan. Benefit from a 10% discount on full payment. With swift sales and satisfied clients, we've achieved our target rapidly. Re-selling bookings are now open, inviting you to be part of this success story.",
  },
  {
    id: 3,
    name: "JANNAT RESIDENCY",
    location: "Main Hayatabad Ring Road, Next to HBK Hypermarket Peshawar",
    imageLink: MARKHOR_STORAGE + "jannatResidency.jpg",
    category: "Commercial & Residential High-rise",

    description:
      "Elevate your lifestyle at Jannat Residency & Jannat Gold Mall Highrise Project, strategically located on the Main Ring Road beside HBK Hypermarket Peshawar. Offering diverse spaces, including Commercial Shops, Gold & Gems Shops, Corporate Offices, and 1 to 2-bedroom Apartments, with a convenient 20% booking and 40-month installments. Act quickly, as 80% of the inventory is already sold out, and enjoy zero minutes away from HBK Arena, Cheezious, MacDonald's, and KFC.",
  },
  {
    id: 1,
    name: "CITY CENTER MALL & APARTMENT",
    location: "Main University Road, Arbab Road Peshawar.",
    imageLink: MARKHOR_STORAGE + "city-center-768x521.jpg",
    category: "Commercial & Residential High-rise",
    description:
      "Indulge in upscale living at City Center Mall & Apartments, centrally located in the heart of Peshawar and just a 1-minute walk from the BRT Station. With bookings starting at just 15% and flexible 36-month installments, choose from 1, 2, and 3-bedroom apartments. With 40% of construction already completed, relish in modern amenities, breathtaking panoramic views, and the perfect harmony of comfort and convenience at City Center.",
  },
];
