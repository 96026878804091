export const clientData = [
  {
    name: "Saleem Afridi",
    role: "U.A.E",
    image: "Saleem.jpg",
    review:
      "Markhor Marketing & Developers top company in KPK, delivering quality projects expectations in every aspect",
  },
  {
    name: "Asif Afridi",
    role: "Investor",
    image:
      "244749261_1237397206744319_8258174943750815419_n-e1691605227611.jpg",
    review:
      "very much impressed with their professionalism & experience in real estate field especially at Peshawar office",
  },
  {
    name: "Niaz Muhammad",
    role: "Dealer",
    image: "243123223_134005998940032_2945744938909679294_n-e1691605452217.jpg",
    review:
      "Perfectly satisfied with their team & quick services in faisal town phase 2 files.					",
  },
];
