import { createContext, useState } from "react";

const muser = {
  mid: 0,
  minst_id: 0,
  mstaff_id: 0,
  msd_id: 0,
  mparent_id: 0,
}

export const UserContext = createContext(muser);

export const UserProvider = ({children}) => {

  const [user, setUser] = useState(muser);

  const startSession = (midpar,minstid,mstaffid,msdid,mparentid) => {
    setUser((user) => ({
      mid: midpar,
      minst_id: minstid,
      mstaff_id: mstaffid,
      msd_id: msdid,
      mparent_id: mparentid,
    }));
  };

  return (
    <UserContext.Provider value={{user, startSession}}>
      {children}
    </UserContext.Provider>
  )
}
