import { Link } from "react-router-dom";

export default function Footers() {
  return (
    <>
      <footer class="p-4 bg-white md:p-8 lg:p-10 ">
        <div class="mx-auto max-w-screen-xl text-center">
          <div className="max-w-lg mx-auto text-center">
            <img
              src="../logo.png"
              className="w-52 mx-auto"
              alt="NK Properties"
            />
          </div>
          <p class="my-6 text-gray-500 ">Get your Ideal Property Now.</p>
          <span class="text-xs md:text-sm text-gray-500 sm:text-center ">
            &copy; {new Date().getFullYear()} NK Properties,{" "}
            <a
              href="https://software.muqadar.com"
              className="hover:text-blue-900"
            >
              MSoftware
            </a>
            . <div className="block md:hidden"></div>
            All Rights reserved.
          </span>
          <p className="text-xs text-gray-500 mt-3">
            <Link to={"../terms"}>Terms & Conditions</Link>
          </p>
        </div>
      </footer>
    </>
  );
}
