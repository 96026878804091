import React from "react";
import ReactDOM from "react-dom/client";
// external
import { BrowserRouter, Routes, Route } from "react-router-dom";

// internal
import App from "./App";
import { UserProvider } from "./context/userContext";

// root
import Home from "./pages/";

const Properties = React.lazy(() => import("./pages/properties/"));
const Property = React.lazy(() => import("./pages/properties/property"));
const Terms = React.lazy(() => import("./pages/terms"));
const About = React.lazy(() => import("./pages/about/about"));
const Projects = React.lazy(() => import("./pages/projects"));
const Services = React.lazy(() => import("./pages/services/services"));
const Project = React.lazy(() => import("./pages/projects/project"));
const Team = React.lazy(() => import("./pages/team"));
const Customer = React.lazy(() => import("./pages/customer"));
const Dealers = React.lazy(() => import("./pages/dealers/dealers"));
const Career = React.lazy(() => import("./pages/career"));
const Certitications = React.lazy(() => import("./pages/certifications"));
const Contact = React.lazy(() => import("./pages/contact"));

const Admin = React.lazy(() => import("./pages/admin"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route
            path="terms"
            element={
              <React.Suspense fallback={<></>}>
                <Terms />
              </React.Suspense>
            }
          />
          <Route
            path="about"
            element={
              <React.Suspense fallback={<></>}>
                <About />
              </React.Suspense>
            }
          />
          <Route
            path="properties"
            element={
              <React.Suspense fallback={<></>}>
                <Properties />
              </React.Suspense>
            }
          />
          <Route
            path="property"
            element={
              <React.Suspense fallback={<></>}>
                <Property />
              </React.Suspense>
            }
          />
          <Route
            path="projects"
            element={
              <React.Suspense fallback={<></>}>
                <Projects />
              </React.Suspense>
            }
          />
          <Route
            path="project"
            element={
              <React.Suspense fallback={<></>}>
                <Project />
              </React.Suspense>
            }
          />
          <Route
            path="services"
            element={
              <React.Suspense fallback={<></>}>
                <Services />
              </React.Suspense>
            }
          />
          <Route
            path="team"
            element={
              <React.Suspense fallback={<></>}>
                <Team />
              </React.Suspense>
            }
          />
          <Route
            path="customer"
            element={
              <React.Suspense fallback={<></>}>
                <Customer />
              </React.Suspense>
            }
          />
          <Route
            path="dealers"
            element={
              <React.Suspense fallback={<></>}>
                <Dealers />
              </React.Suspense>
            }
          />
          <Route
            path="career"
            element={
              <React.Suspense fallback={<></>}>
                <Career />
              </React.Suspense>
            }
          />
          <Route
            path="certifications"
            element={
              <React.Suspense fallback={<></>}>
                <Certitications />
              </React.Suspense>
            }
          />
          <Route
            path="contact"
            element={
              <React.Suspense fallback={<></>}>
                <Contact />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path="admin"
          element={
            <React.Suspense fallback={<></>}>
              <Admin />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h2>There's nothing here on this root page.</h2>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  </UserProvider>
);
