import { Link } from "react-router-dom";
import { ProjectsData } from "../../data/projects";
import { Avatar } from "@mui/material";
import { MARKHOR_STORAGE } from "../../config/mstorage";

export default function ProjectsSm() {
  return (
    <section className="py-14">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="text-gray-500 text-xl font-semibold">
            Featured Projects
          </h3>
          <p className="text-gray-600 mt-3">
            Invest in the best Projects at NK Properties.
          </p>
        </div>

        <div className="mt-12 masonry-grid masonry-grid-count-2">
          {Object.values(ProjectsData)
            .slice(0, 2)
            .map((items, key) => (
              <div
                className="masonry-grid__item !p-0 text-center border hover:bg-gray-50 hover:shadow-sm  hover:border-green-700"
                key={key}
              >
                <Link to={"../projects"}>
                  <div className="lg:flex">
                    <div className="basis-3/4 p-2">
                      <img
                        src={items.imageLink}
                        loading="lazy"
                        alt={items.name}
                        className="w-full rounded-lg "
                      />
                    </div>
                    <div className="basis-2/4 text-left">
                      <div className="mt-3 px-3 py-3 space-y-2">
                        <h3 className="text-xl text-gray-800 duration-150 font-bold">
                          {items.name}
                        </h3>
                        <p className="text-gray-600 duration-150 group-hover:text-gray-800">
                          {items.location}
                        </p>
                        <p className="text-gray-600 hidden text-lg duration-150 group-hover:text-gray-800">
                          {items.description}
                        </p>
                        <p className="text-gray-400 duration-150 group-hover:text-gray-800">
                          Type : {items.category}
                        </p>
                        <div className="text-gray-400 !my-3 space-x-2 duration-150 group-hover:text-gray-800">
                          <button className="rounded-full text-sm px-4 text-white py-2 bg-green-600">
                            Learn More
                          </button>
                          <button className="rounded-full text-sm px-4 text-green-500 border border-green-500 py-2 bg-white">
                            Contact
                          </button>
                        </div>
                        <div className="hidden xl:block">
                          <div className="text-right space-x-2 justify-start flex items-center">
                            <Avatar
                              className="!w-8 !h-8"
                              src={MARKHOR_STORAGE + "mir-brothers.png"}
                            />
                            <span className="text-xs">MK Properties</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
