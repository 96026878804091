import React from "react";
// external
import { Outlet } from "react-router-dom";
// internal
import "./style/add/add.css";
import "./style/style.css";
import ScrollToTop from "./component/scrollToTop";
import "aos/dist/aos.css";
// import Header from "./pages/Header";
import AOS from "aos";
import { useEffect } from "react";
import Footer from "./pages/Footer";
import Header from "./component/header/header";

function App() {
  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="App">
      {/* <Header /> */}
      <Header />
      <ScrollToTop />
      <div className="md:mt-28">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
