import { PropertiesData } from "../../data/properties";
import { TbBath, TbBed } from "react-icons/tb";
import { TbRuler } from "react-icons/tb";
import { Avatar } from "@mui/material";
import React from "react";
import { MARKHOR_STORAGE } from "../../config/mstorage";
import { Link } from "react-router-dom";

export default function PropertiesFeatured() {
  return (
    <section className="pb-14">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h3 className="text-gray-500 text-xl font-semibold">
            Featured Properties
          </h3>
          <p className="text-gray-600 mt-3">
            Rent or Buy the best Properties at NK Properties.
          </p>
        </div>
        <div className="mt-4 masonry-grid masonry-grid-count-4">
          {Object.values(PropertiesData)
            .slice(0, 4)
            .map((items, key) => (
              <div
                className="masonry-grid__item !p-0 text-center border hover:bg-gray-50 hover:shadow-sm  hover:border-green-700"
                key={key}
              >
                <Link to={"../properties"}>
                  <div className="">
                    <div className="basis-3/4 p-2">
                      <img
                        src={items.profile}
                        loading="lazy"
                        alt={items.name}
                        className="w-full rounded-lg "
                      />
                    </div>
                    <div className="basis-2/4 text-left">
                      <div className="mt-3 px-3 py-3 space-y-2">
                        <h3 className="text-3xl text-gray-800 duration-150 font-bold">
                          <span className="text-xl">PKR</span> {items.rent}
                        </h3>
                        <p className="text-gray-600 duration-150 group-hover:text-gray-800">
                          {items.address}
                        </p>
                        <div className="inline-flex items-center space-x-3">
                          <div className="inline-flex items-center space-x-2 rounded-full px-2 bg-gray-100 ">
                            <TbBed className="prime-color" />
                            <span>{items.Rooms}</span>
                          </div>
                          <div className="inline-flex items-center space-x-2 rounded-full px-2 bg-gray-100 ">
                            <TbBath className="prime-color" />
                            <span>{items.Bathrooms}</span>
                          </div>
                          <div className="inline-flex items-center space-x-2 rounded-full px-2 bg-gray-100 ">
                            <TbRuler className="prime-color" />
                            <span>{items.Size}</span>
                          </div>
                        </div>
                        <p className="text-gray-600 text-sm duration-150 group-hover:text-gray-800">
                          {items.description}
                        </p>
                        <div className="text-gray-400 !my-3 space-x-2 duration-150 group-hover:text-gray-800">
                          <button className="rounded-full text-sm px-4 text-white py-2 bg-green-600">
                            Rent
                          </button>
                          <button className="rounded-full text-sm px-4 text-green-500 border border-green-500 py-2 bg-white">
                            Contact
                          </button>
                        </div>
                        <div className="hidden xl:block">
                          <div className="text-right space-x-2 justify-start flex items-center">
                            <Avatar
                              className="!w-8 !h-8"
                              src={MARKHOR_STORAGE + "mir-brothers.png"}
                            />
                            <span className="text-xs">MK Properties</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
