// external

// internal
import * as React from "react";
import Footer from "./Footer";
import "../style/root/index.css";
import Hero from "../component/hero";
import HeroCarousal from "../component/heroCarousal/heroCarousal";
import ProjectsSm from "./projects/index.sm";
import LeadershipSm from "./leadership/leadership.sm";
import TeamSm from "./team/team.sm";
import CustomerSm from "./customer/customer.sm";
import DealersSm from "./dealers/dealers.sm";
import ContactSm from "./contact/contact.sm";
import PropertiesFeatured from "./properties/properties.featured";

function Home() {
  return (
    <>
      <div className="md:mt-16">
        {/* <HeroCarousal /> */}
        <Hero />
        <PropertiesFeatured />
        <ProjectsSm />
        {/* 
        <LeadershipSm />
        <TeamSm /> */}
        {/* <CustomerSm /> */}

        <DealersSm />
        <ContactSm />
      </div>
    </>
  );
}

export default Home;
