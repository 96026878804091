import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

export default function ContactSm() {
  return (
    <main className="py-14">
      <div className="max-w-screen-xl text-center mx-auto px-4 text-gray-600 md:px-8">
        <div className="space-y-3">
          <h3 className="text-green-800 font-semibold">Contact</h3>
          <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
            Let us know how we can help
          </p>
          <p>
            We’re here to help and answer any question you might have, We look
            forward to hearing from you .
          </p>
        </div>
        <div className="masonry-grid masonry-grid-count-3 mt-10">
          <div className="masonry-grid__item border">
            <div className="!px-3 !py-4 rounded-lg">
              <div className="text-center text-gray-400">
                <div className="inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                </div>
              </div>
              <h4 className="text-gray-800 text-lg font-medium">Phone</h4>
              <div className="mt-3 text-center">
                <p>03130123999</p>
              </div>
            </div>
          </div>
          <div className="masonry-grid__item border">
            <div className="!px-3 !py-4 rounded-lg">
              <div className="text-center text-gray-400">
                <div className="inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </div>
              </div>
              <h4 className="text-gray-800 text-lg font-medium">Email</h4>
              <div className="mt-3 text-center">
                <p>info@nkproperties.com.pk</p>
              </div>
            </div>
          </div>

          <div className="masonry-grid__item border">
            <div className="!px-3 !py-4 rounded-lg">
              <div className="text-center text-gray-400">
                <div className="inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                    />
                  </svg>
                </div>
              </div>
              <h4 className="text-gray-800 text-lg font-medium">Office</h4>
              <div className="mt-3 text-center">
                <p className="text-base">XYZ, Peshawar, Pakistan.</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="mt-10 text-center">
          <div className="inline-block">
            <div className="flex space-x-2">
              {contactMethods.map((item, idx) => (
                <div className="border !px-3 !py-4 rounded-lg" key={idx}>
                  <div className="text-center text-gray-400">
                    <div className="inline-block">{item.icon}</div>
                  </div>
                  <h4 className="text-gray-800 text-lg font-medium">
                    {item.title}
                  </h4>
                  <div className="mt-3 text-center">
                    <p>{item.contact}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="space-y-3 mt-10 pb-4">
          <h1 className="text-lg font-semibold">Timing</h1>
        </div>
        <div className="text-center">
          <h1>9 am to 6 pm</h1>
          <h1>Monday to Saturday</h1>
        </div>
        <div className="space-y-3 mt-10">
          <h1 className="text-lg font-semibold">Social Media</h1>
        </div>
        <div className="text-center mt-5">
          <div className="inline-block">
            <div className="flex">
              {Object.values(SocialMedia).map((s, index) => (
                <a href={s.link} target="_blank" rel="noreferrer">
                  <div className="border px-4 py-4 mx-2 text-2xl rounded-full text-green-700 hover:text-white hover:bg-green-700 duration-200 cursor-pointer">
                    {s.icon}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const SocialMedia = [
  {
    name: "Facebook",
    icon: <FaFacebook />,
    link: "https://www.facebook.com/Nkproperties.com.pk",
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/in/nkproperties",
  },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    link: "https://www.instagram.com/nkproperties.com.pk",
  },
  // {
  //   name: "Youtube",
  //   icon: <FaYoutube />,
  //   link: "https://facebook.com",
  // },
  // {
  //   name: "Twitter",
  //   icon: <FaTwitter />,
  //   link: "https://facebook.com",
  // },
];
