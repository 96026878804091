import { GiHouseKeys } from "react-icons/gi";
import { BsHouseAddFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <section class="bg-white ">
      <div class="pt-2 px-4 mx-auto max-w-screen-xl text-center lg:pt-10 lg:px-12">
        <Link
          to="../projects"
          class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-green-700 bg-gray-50 rounded-full  hover:bg-gray-100 "
          role="alert"
        >
          <span class="text-xs bg-primary-600 rounded-full bg-green-200 text-green px-4 py-1.5 mr-3">
            New
          </span>{" "}
          <span class="text-sm font-medium">
            The best Project to Invest In.
          </span>
          <svg
            class="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </Link>
        <h1 class="mb-4 text-4xl font-black tracking-tight leading-none prime-color md:text-5xl lg:text-6xl ">
          Find Your Ideal Property
        </h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">
          Wether you need for rent or to buy we got you cover.
        </p>
        <div class="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <Link
            to="../properties"
            class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-green-600 rounded-full border border-green-300 bg-green-100 hover:shadow-md hover:text-green-700 focus:ring-4 focus:ring-green-300 "
          >
            <GiHouseKeys />
            <span className="ml-2">Rent a Property</span>
          </Link>
          <Link
            to="../properties"
            class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-green-600 rounded-full border border-green-300 bg-green-100 hover:shadow-md hover:text-green-700 focus:ring-4 focus:ring-green-300 "
          >
            <BsHouseAddFill />
            <span className="ml-2">Buy a Property</span>
          </Link>
          <Link
            to="../projects"
            class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-green-600 rounded-full border border-green-300 bg-green-100 hover:shadow-md hover:text-green-700 focus:ring-4 focus:ring-green-300 "
          >
            <BsHouseAddFill />
            <span className="ml-2">Invest in Projects</span>
          </Link>
        </div>
      </div>
    </section>
  );
}
