import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "./inst.css";

export default function Header() {
  const [state, setState] = useState(false);

  const navigation = [
    { title: "Properties", path: "properties", isDrapdown: false },
    { title: "Projects", path: "projects", isDrapdown: false },
    { title: "Agents", path: "dealers", isDrapdown: false },
    { title: "About", path: "about", isDrapdown: false },
    // { title: "Services", path: "services", isDrapdown: false },
    // { title: "Team", path: "team", isDrapdown: false },
    // { title: "Customer", path: "customer", isDrapdown: false },
    { title: "Careers", path: "career", isDrapdown: false },
    // { title: "Certifications", path: "certifications", isDrapdown: false },
    // { title: "Contact", path: "contact", isDrapdown: false },
  ];

  useEffect(() => {
    document.onclick = (e) => {
      const target = e.target;
      if (!target.closest(".menu-btn")) setState(false);
    };
  }, []);

  window.onscroll = function () {
    if (window.innerWidth > 768) {
      scrollFunction();
    }
  };

  function scrollFunction() {
    const logo = document.getElementById("logo");

    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      logo.style.width = "100px";
    } else {
      logo.style.width = "150px";
    }

    logo.style.transition = "width 700ms ease";
  }

  return (
    <div className="md:fixed md:inset-x-0 top-0 z-50 w-full mx-auto">
      <nav
        className={` md:bg-white head-bg md:text-sm  ${
          state
            ? "shadow-md md:border-gray-50 rounded-xl mx-2 py-4 mt-2 md:shadow-none md:border-none md:mx-2 md:mt-0"
            : ""
        }`}
      >
        <div className="gap-x-14  items-center max-w-screen-2xl mx-auto px-4 md:flex md:px-8">
          <div className="flex items-center justify-between py-2 md:block">
            <div className="text-center">
              <Link to={"../"}>
                <div className="inline-block">
                  <img
                    src="../logoNoBg.png"
                    className="w-24 sm:w-30"
                    id="logo"
                    alt={"Markhor Marketing & Developer"}
                  />
                </div>
              </Link>
            </div>
            <div className="md:hidden">
              <button
                className="menu-btn text-gray-500 hover:text-gray-800"
                onClick={() => setState(!state)}
              >
                {state ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div
            className={`flex-1 items-center mt-8 md:mt-0 md:flex ${
              state ? "block" : "hidden"
            } `}
          >
            <ul className="justify-center items-center space-y-6 md:flex md:space-x-2 md:space-y-0">
              {navigation.map((item, idx) => {
                return (
                  <li
                    key={idx}
                    className="text-gray-700 hover:bg-green-50 px-2 py-1 rounded-full hover:text-gray-900"
                  >
                    <NavLink
                      to={item.path}
                      className="block"
                      style={({ isActive }) => {
                        return { opacity: isActive ? "0.5" : "" };
                      }}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <div className="flex-1 gap-x-6 items-center justify-end mt-6 space-y-6 md:flex md:space-y-0 md:mt-0">
              <Link
                to={"contact"}
                className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-green-700 hover:bg-green-800 rounded-full md:inline-flex"
              >
                Signup
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
