import { MARKHOR_STORAGE } from "../config/mstorage";

export const TeamData = [
  {
    name: "Nadeem Khan",
    position: "CEO",
    image: MARKHOR_STORAGE + "nadeem.jpg",
  },
  {
    name: "Hamza Afridi",
    position: "Managing Director",
    image: MARKHOR_STORAGE + "hamza.jpg",
  },
  {
    name: "Kamran Afridi",
    position: "Director Marketing",
    image: MARKHOR_STORAGE + "kamran.jpg",
  },
  {
    name: "Sehrish Wazir",
    position: "Assistant Manager",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
  {
    name: "Muhammad Zarar",
    position: "Web Designer",
    image: MARKHOR_STORAGE + "zarar2-1024x1024.jpg",
  },
  {
    name: "Fazal Rahman",
    position: "Graphic Designer",
    image:
      MARKHOR_STORAGE +
      "WhatsApp-Image-2023-11-14-at-1.49.25-AM-e1700056213486.jpeg",
  },
  {
    name: "Bilal Afridi",
    position: "Senior Accountant",
    image: MARKHOR_STORAGE + "bilal.jpg",
  },
  {
    name: "Abid Ullah Khan",
    position: "Accountant",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
  {
    name: "Abdul Qadir Afridi",
    position: "Senior Executive Marketing & Sales",
    image: MARKHOR_STORAGE + "qadir.jpg",
  },
  {
    name: "Muskan",
    position: "Senior Sales Executive",
    image:
      MARKHOR_STORAGE +
      "WhatsApp-Image-2023-11-15-at-6.42.52-PM-e1700057353830.jpeg",
  },
  {
    name: "Huma Wahab",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
  {
    name: "Abreen Khattak",
    position: "Sales Executive",
    image:
      MARKHOR_STORAGE +
      "WhatsApp-Image-2023-11-15-at-6.51.49-PM-e1700057622837.jpeg",
  },
  {
    name: "Ayesha Malik",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
  {
    name: "Malik Aftab",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "aftab-e1700058464371.jpg",
  },
  {
    name: "Anas Khan",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "Anas-e1700059309424.jpg",
  },
  {
    name: "Momna Qadeel",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
  {
    name: "Tahmeena",
    position: "Sales Executive",
    image: MARKHOR_STORAGE + "icon-1.png",
  },
];
